import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab'
import Swiper from 'swiper';

/**
 * Swiper slider
 */

const bannerSwiper = new Swiper('#swiper-banner', {
    preloadImages: false,
    lazy: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        loop: true
    },
    autoplay: {
        delay: 8000,
    },
});

const directorSwiper = new Swiper('#swiper-director', {
    breakpointsInverse: true,
    slidesPerView: 1.5,
    slidesPerColumn: 2,
    spaceBetween: 40,
    breakpoints: {
        768: {
            slidesPerView: 3,
            slidesPerColumn: 2,
        },
        991: {
            slidesPerView: 4,
            slidesPerColumn: 2,
            spaceBetween: 20,
        }
    },
});

const serviceSwiper = new Swiper('#swiper-service', {
    breakpointsInverse: true,
    slidesPerView: 1.2,
    spaceBetween: 20,
    slidesPerColumn: 1,
    breakpoints: {
        768: {
            slidesPerView: 2,
            slidesPerColumn: 1,
        },
        991: {
            slidesPerView: 3,
            slidesPerColumn: 3,
            slidesPerView: 3,
        }
    },
    pagination: {
        el: '.swiper-pagination-service',
        clickable: true,
        loop: true
    }
});

const moreSwiper = new Swiper('#swiper-more', {
    breakpointsInverse: true,
    slidesPerView: 1.2,
    spaceBetween: 20,
    slidesPerColumn: 1,
    pagination: {
        el: '.swiper-pagination-more',
        clickable: true,
        loop: true
    }
});

const logoSwiper = new Swiper('#swiper-logo', {
    preloadImages: false,
    lazy: true,
    watchSlidesVisibility: true,
    breakpointsInverse: true,
    navigation: {
        nextEl: '.swiper-logo__next',
        prevEl: '.swiper-logo__prev',
    },
    slidesPerView: 2,
    loop: true,
    pagination: {
        el: '.swiper-pagination-logo',
        clickable: true,
        loop: true
    },
autoplay: {
    delay: 1300,
},
    breakpoints: {
        768: {
            slidesPerView: 4
        },
        991: {
            slidesPerView: 6
        }
    },
});

/**
 * Swiper slider end
 */




jQuery(document).ready(function(){
    const url = jQuery('#site-url').text();

    /**
        * Desktop Mega Menu
    */

    let menuItems = jQuery('#menu-main-menu > li.menu-item-has-children');
    
    menuItems.each(function(i ,li){

        let submenuHeaders = jQuery(li).find("> ul > li");
        let width = 0;

        jQuery(submenuHeaders).each(function(i, item){
            width =+ jQuery(item).outerWidth();
        });

        jQuery(li).mouseover(function(e){
            let submenu = jQuery(li).find("> ul");
            let submenuRightPos = submenu.offset().left + submenu.outerWidth();
            if(submenuRightPos > jQuery(window).width()){
                jQuery(submenu).css({"right":"0"})
            }
        });
    });
    

    /**
        * Desktop Mega Menu End
    */


    /**
     * Mobile Menu
     */

    let items = jQuery("#mobile-menu #menu-main-menu-1 > li.menu-item-has-children > a, #mobile-menu #menu-top-menu-1 > li.menu-item-has-children > a");
    let toggleMenu = jQuery('.toggle-menu');

    jQuery(toggleMenu).each(function(i, item){
        jQuery(item).click(function(){
            jQuery('.header__mobile-menu').toggleClass("closed");
        })
    });

    /**
     * Mobile Menu End
     */



    /**
     * Search
     */
    jQuery('.search-toggle').click(function(){
        jQuery('.form-search').toggleClass("search--open")
    });
    /**
     * Search End
     */




    /**
     * Stocklist Search
     */

    jQuery('#stocklist-form').submit(function(e){
        e.preventDefault();
        let input = jQuery("#stocklist-input").val();
        jQuery('#locations-container').append('<div class="loader"></div>');
        let locations = get_distances(input);
    });

    function sort_array(a, b){
        if ( a.distance < b.distance ){
            return -1;
        }
        if ( a.distance > b.distance ){
            return 1;
        }
        return 0;
    }

    function locationFormError(message){
        jQuery('#warning').text(message);
        jQuery('#warning').animate({
            'opacity': 1,
            'top': "0px"
        }, 400).delay(3000).animate({
            'opacity': 0,
            "top" : "10px"
        });
        jQuery('#locations-container').html("");
    }

    function get_distances(user_input){
        if( user_input.length == 0 ){
            locationFormError("Please enter a valid postcode or town");
        }
        
        jQuery.when(get_user_location(user_input), get_all_locations()).done(function(user_location, all_locations){

            if( user_location[0].results.length == 0 ){
                locationFormError("Please enter a valid postcode or town");
                return;
            }

            let user_lng = user_location[0].results[0].geometry.location.lng;
            let user_lat = user_location[0].results[0].geometry.location.lat;
            
            for(let i = 0; i < all_locations[0].length; i++){
                let location = all_locations[0][i];
                let curr_lng = location.longitude;
                let curr_lat = location.latitude;

                location.distance = get_distance(user_lat, user_lng, curr_lat, curr_lng);
            }

            all_locations[0].sort(function(a,b) {return (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0);} );

            jQuery('#locations-container').html('');

            for(let i = 0; i < all_locations[0].length; i++){
                let location = all_locations[0][i];

                let location_code = '<div class="col-md-6 col-lg-3 location mb-4">'
                + '<div class="bg-grey">'
                +    '<div class="location__title text-center">' 
                +        '<h4>' + location.title.rendered + '</h4>'
                +    '</div>'

                +    '<div class="location__wrapper">'
                +        '<div class="location__address">'
                +            '<p>' + location.address + '</p>'
                +        '</div>'

                +        '<div class="location__telephone">'
                +        '<a href="tel:' + location.telephone + '">T: ' + location.telephone + '</a>'
                +        '</div>'

                +        '<div class="location__fax">'
                +            '<a href="fax:' + location.fax + '">F: ' + location.fax + '</a>'
                +        '</div>'

                +        '<div class="location__email">'
                +            '<a href="mailto:' + location.email + '">' + location.email + '</a>'
                +       '</div>'
                +       '<div class="location__distance">Distance: ' + location.distance + ' Miles <div>'
                +    '</div>'
                + '</div>'
            + '</div>'
            
            jQuery('#locations-container').append(location_code);
            }
        });
    }

    function get_all_locations(){
        return jQuery.getJSON(url + '/wp-json/wp/v2/location');
    }

    function get_user_location(address){
        return jQuery.getJSON("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=AIzaSyBtcbKSGRaP_PZIReToTKP2yLRdHCcVU20");
    }

    function get_distance(lat1,lon1,lat2,lon2) {
        var R = 3958.8; // km (change this constant to get miles)
        var dLat = (lat2-lat1) * Math.PI / 180;
        var dLon = (lon2-lon1) * Math.PI / 180;
        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(lat1 * Math.PI / 180 ) * Math.cos(lat2 * Math.PI / 180 ) *
            Math.sin(dLon/2) * Math.sin(dLon/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c;
        if (d>1) return Math.round(d);
        else if (d<=1) return Math.round(d*1000);
        return parseFloat(d);
    }
    /**
     * Stocklist Search End
     */




    /**
    * Form upload text
    */
    jQuery('input#upload-cv').change((e)=>{
        let file_name = jQuery('input#upload-cv').val().split('\\');
        jQuery('.upload-cv').text(file_name[file_name.length - 1]);
    });
    /**
    * End form upload text
    */




    /**
     * Video start
     */
    jQuery('.mejs-overlay-button, .mejs-video').click(function(){
        jQuery('.mejs-mediaelement').css({'opacity' : 1});
    });
    /**
      * Video end
      */




    /**
     * Sticky Nav
     */
    if( jQuery(window).width() < 992 ){
        jQuery(window).scroll(function(){
            let scroll = jQuery(window).scrollTop();
            let menu = jQuery('.header__sticky');
            if( scroll > 300 ){
                if(! menu.hasClass('header__sticky--scrolled')){
                    menu.addClass("header__sticky--scrolled");
                }
            }
            else {
                menu.removeClass("header__sticky--scrolled");
            }
        });
    }
    /**
     * Sticky Nav End
     */



    /**
    * News load more
    */
    
    const featured_id = jQuery('featured-article-id').text();

    jQuery('#load-more-articles').click(()=>{
        let button = jQuery('#load-more-articles');
        let count = jQuery(button).data('page-count');
        let page = 2;
        jQuery(button).data('page-count', count--);
        if( count == 0 ){
            jQuery(button).hide();
        }
        jQuery('#article-loader').addClass('loader');
        jQuery.getJSON(url + "/wp-json/wp/v2/posts/?_embed&per_page=8&page=" + page + "&exclude=" + featured_id, function(response){
            jQuery('#article-loader').removeClass('loader');
            page++;
            jQuery(response).each((i, post)=>{
                let image = post._embedded['wp:featuredmedia']['0'].source_url
                jQuery('#ajax-articles').append(
                    `<div class="col-12 col-lg-3">
                    <div class="post bg-grey">
                        <div class="post__image" style="background-image:url(${image})">

                        </div>
                        <div class="post__content">
                            <h3 class="post__title">${post.title.rendered}</h3>
                            <div class="post__content--excerpt">
                                ${post.excerpt.rendered}
                            </div>
                            <a href="${post.link}">
                                <img class="yellow-arrow-box" src="${url}/wp-content/themes/carwood/assets/images/icons/right-arrow-angle-blue.svg" alt="right blue arrow">
                            </a>
                        </div>
                    </div>
                </div>`
                );
            });
        });
    });

    /**
    * End News load more
    */


    
    /**
     * Expand content
     */
    if( jQuery(window).width() < 991 ){
        jQuery('.expand-button').each(function(i, button){
            let paragraphs = jQuery(button).siblings('.service__compress').find('p, ul')
            
            if(paragraphs.length == 1){
                jQuery(button).hide()
            }
            
            jQuery(paragraphs).each(function(i, paragraph){
                if(i !== 0){
                    jQuery(paragraph).hide()
                }
            })
        });
    
        jQuery('.expand-button').click(function(e){
            let button = e.target
            let paragraphs = jQuery(e.target).siblings('.service__compress').find('p, ul')

            if( jQuery(button).data("state") == "closed" ){
                jQuery(button).data("state", "open")
                jQuery(button).text("Show less -")
            }
            else {
                jQuery(button).data("state", "closed")
                jQuery(button).text("Show more +")
            }

            jQuery(paragraphs).each(function(i, paragraph){
                if(i !== 0){
                    jQuery(paragraph).toggle()
                }
            })
        });
    }

    /**
     * Expand content end
     */




    /**
    * Tab underline
    */

    jQuery('.underline').animate({
        'width' : jQuery('.tab-section-container .tab-section__nav--link.active').width()
    });

    jQuery('.tab-section-container .tab-section__nav--link').on("hover", function(){
        jQuery('.underline').stop().animate({
            'left': jQuery(this).position().left, 
            "width": jQuery(this).width()
        }, 400);
    });

    jQuery('.tab-section__nav--link').on('click', function(){
        //Lees code
        var finishedAnimating = new Promise((resolve, reject) => {
            resolve(
                jQuery('.underline').stop().animate({
                    'left': jQuery(this).position().left, 
                    "width": jQuery(this).width()
                }, 400)
            )})

        finishedAnimating.then(() => {
            setTimeout(() => {
                jQuery('.underline').stop().animate({
                    'left': jQuery(this).position().left, 
                    "width": jQuery(this).width()
                }, 400);
            }, 10)
        })
    });

    /**
    * End tab underline
    */
});

jQuery('#mobile-menu #menu-main-menu-1 > li > ul, #mobile-menu #menu-top-menu-1 > li > ul').each(function () {
    jQuery(this).before(
        jQuery('<div class="mobile-arrow"></div>')
            .click(function() {
                jQuery(this)
                    .toggleClass('active')
                    .parent('li')
                    .toggleClass('active')

                return jQuery(this)
            })
    );
});